import React, { useEffect } from "react";
import { ERROR_CODES, POST_MESSAGE_ERRORS } from "../utils/constants";

export default function useWindowPostMessages({ iframeRef, setErrorCode }) {
  useEffect(() => {
    window.addEventListener("message", function (e) {
      iframeRef?.current?.contentWindow?.postMessage(e.data, "*");
      if (typeof e.data === "string") {
        let errorMessage;
        try {
          errorMessage = JSON.parse(e.data).error;
        } catch {
          return;
        }
        if (errorMessage === POST_MESSAGE_ERRORS.INSTALLATION_FAILED) {
          setErrorCode(ERROR_CODES.installationFailed);
        } else if (errorMessage === POST_MESSAGE_ERRORS.STREAM_NOT_FOUND) {
          setErrorCode(ERROR_CODES.streamNotFound);
        } else if (errorMessage === POST_MESSAGE_ERRORS.SESSION_EXPIRED) {
          setErrorCode(ERROR_CODES.sessionExpired);
        } else if (errorMessage === POST_MESSAGE_ERRORS.DUPLICATE_TAB) {
          setErrorCode(ERROR_CODES.duplicatedTab);
        } else if (errorMessage === POST_MESSAGE_ERRORS.SESSION_IDLE) {
          setErrorCode(ERROR_CODES.sessionIdle);
        } else if (
          errorMessage === POST_MESSAGE_ERRORS.PIXEL_STREAMING_SESSION_IDLE
        ) {
          setErrorCode(ERROR_CODES.pixelStreamingSessionIdle);
        } else if (errorMessage === POST_MESSAGE_ERRORS.CONNECTION_FAILED) {
          setErrorCode(ERROR_CODES.connectionFailed);
        }
      }

      if (
        e.data.target === "vagon-streams-sdk-asas" &&
        e.data.payload === "##FOCUSIFRAME##"
      ) {
        iframeRef?.current?.focus();
      }
    });
  }, []);
}

import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";

const apiGenerator = (type) => {
  const headers = {
    "content-type": "application/json",
  };

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers,
  };

  switch (type) {
    case "get":
      return (url, options = {}) =>
        axios.get(url, { ...defaultOptions, ...options });
    case "post":
      return (url, data, options = {}) =>
        axios.post(url, data, { ...defaultOptions, ...options });
    case "patch":
      return (url, data, options = {}) =>
        axios.patch(url, data, { ...defaultOptions, ...options });
    case "put":
      return (url, data, options = {}) =>
        axios.put(url, data, { ...defaultOptions, ...options });
    case "delete":
      return (url, data, options = {}) =>
        axios.delete(url, { data, ...defaultOptions, ...options });
    default:
      return null;
  }
};

export function useAPI(props) {
  const { type = "get", endpoint, onError, onSuccess } = props;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const mountedRef = useRef(false);

  // effect just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const apiCallback = useCallback(
    async (body = {}, options = {}, urlParams) => {
      setIsLoading(true);
      const api = apiGenerator(type);

      api(urlParams ? endpoint(urlParams) : endpoint, body || options, options)
        .then((response) => {
          if (mountedRef.current) {
            setData(response.data);
            setIsLoading(false);
            if (onSuccess) onSuccess(response.data);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            setError(err);
            setIsLoading(false);
            if (onError) onError(err);
          }
        });
    },
    [endpoint, onError, type, onSuccess]
  );

  return {
    api: apiCallback,
    data,
    error,
    isLoading,
  };
}

import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Streams from "pages/Streams/Streams";
import Home from "pages/Home/Home";
import NotFound from "pages/NotFound/NotFound";

import "./utils/i18n";
import "./assets/styles/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Router>
      <Routes>
        <Route path="/streams/:id" element={<Streams />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Router>
  </>
);

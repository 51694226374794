import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        errorCode: {
          3001: "Installation failed. Contact your system admin.",
          3002: "Session expired, you can reconnect again.",
          3003: "Visitor information is invalid, contact your system admin.",
          3004: "Stream password is incorrect.\nCheck the password or contact your system admin.",
          3006: "Connection expired due to inactivity. \nYou can reconnect again.",
          3007: "The connection ended because you haven't joined.\nYou can reconnect again.",
          4001: "Stream not found. Contact your system admin.",
          4002: "Stream is not active. Contact your system admin.",
          4003: "Getting ready",
          4004: "Stream capacity is full in your region at the moment.\nPlease retry connecting in a few minutes.",
          4005: "Stream is not available at the moment.\nPlease contact your system admin to check availability.",
          4006: "Connection expired, you can reconnect again.",
          404: "The page you are looking for is not valid.",
        },
        queueMessage:
          "{{message}}\n<span>You're currently {{queueToken}} in line</span>",
      },
    },
  },
});

export default i18n;

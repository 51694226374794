export const ERROR_CODES = {
  installationFailed: 3001,
  sessionExpired: 3002,
  emailError: 3003,
  passwordError: 3004,
  localStorage: 3005,
  sessionIdle: 3006,
  pixelStreamingSessionIdle: 3007,
  streamNotFound: 4001,
  streamNotActive: 4002,
  machinesGettingReady: 4003,
  streamCapacityFull: 4004,
  streamNotAvailable: 4005,
  duplicatedTab: 4006,
  connectionFailed: 4007,
};

export const POST_MESSAGE_ERRORS = {
  INSTALLATION_FAILED: "installation_failed",
  STREAM_NOT_FOUND: "stream_not_found",
  SESSION_EXPIRED: "session_expired",
  DUPLICATE_TAB: "duplicate_tab",
  SESSION_IDLE: "session_idle",
  PIXEL_STREAMING_SESSION_IDLE: "pixel_streaming_session_idle",
  CONNECTION_FAILED: "connection_failed",
};

export const ERROR_CODE_MESSAGE_MAPPINGS = {
  3001: "installation_failed_text",
  3002: "session_expired_text",
  3003: "invalid_email_text",
  3004: "invalid_password_text",
  3006: "idle_text",
  4002: "not_active_text",
  4005: "not_active_text",
  4007: "connection_failed_text",
};

export const INITIALIZING_STREAM_TEXT = "Initializing";
export const RETRY_CONNECTION_TEXT = "Connecting to the Stream...";

export const API_ROUTES = {
  getStream: (streamUID) => `api/v1/streams/${streamUID}`,
  connectStream: "api/v1/streams/connect",
  createUser: "api/v1/streams/create-user",
  authenticateUser: "api/v1/streams/authenticate-user",
};

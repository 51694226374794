import React from "react";
import classNames from "classnames/bind";

import "./Initializing.styles.scss";

const Initializing = ({
  text,
  enterprise,
  logo,
  banner,
  darkMode,
  loaderRef,
}) => {
  return (
    <div
      ref={loaderRef}
      className={classNames("initializing-container", {
        dark: darkMode,
        enterprise: enterprise,
      })}
    >
      <div className="content-box">
        <img src={logo} className="app-logo" alt="nope" />
        <p dangerouslySetInnerHTML={{ __html: text }} />
        <div className="app-streaming-progress-bar">
          <div className="progress" />
        </div>
      </div>
    </div>
  );
};

export default Initializing;

import React from "react";
import classNames from "classnames/bind";
import Lock from "assets/images/lock.png";
import { useAPI } from "utils/helpers/api.helpers";
import { API_ROUTES, ERROR_CODES } from "utils/constants";
import { isMobile } from "utils/helpers/browser.helpers";

import "./EmailCollect.styles.scss";

const EmailCollect = ({
  logo,
  darkMode,
  enterprise,
  streamUID,
  setUserID,
  setErrorCode,
}) => {
  const [email, setEmail] = React.useState("");

  const { api: createUserAPI, isLoading: createUserLoading } = useAPI({
    endpoint: API_ROUTES.createUser,
    type: "post",
    onSuccess: (data) => {
      setUserID(data.user_id);
    },
    onError: () => {
      setErrorCode(ERROR_CODES.emailError);
    },
  });

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    createUserAPI({ id: streamUID, email });
  };
  return (
    <div
      className={classNames("email-collect-container", {
        dark: darkMode,
        enterprise: enterprise,
      })}
    >
      <div className="app-logo-container">
        <img src={logo} alt="nope" />
      </div>

      <div className="content-box">
        <img className="error-logo" src={Lock} alt="nope" />

        <form onSubmit={(e) => handleSubmit(e)} className="email-form">
          <label>Email Address</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="visitor@emailaddress.com"
          />
          <button disabled={createUserLoading} type="submit">
            Join Stream
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailCollect;

export const getItemFromLocalStorage = (itemName, defaultValue = undefined) => {
  try {
    const storedState = localStorage.getItem(itemName);

    if (storedState === null || storedState === undefined) {
      localStorage.removeItem(itemName);
      return defaultValue;
    }

    return JSON.parse(storedState);
  } catch (error) {
    return defaultValue;
  }
};

export const removeItemFromLocalStorage = (localStorageItem) => {
  try {
    localStorage.removeItem(localStorageItem);
  } catch (error) {
    // Failed to access local storage
  }
};

export const saveItemToLocalStorage = (itemName, state, override) => {
  try {
    if (override) {
      localStorage.setItem(itemName, state);
      return;
    }
    // Check if the state exists
    const storedState = getItemFromLocalStorage(itemName);
    const serializedState = JSON.stringify(
      typeof storedState === "object"
        ? Object.assign(storedState, state)
        : state
    );

    localStorage.setItem(itemName, serializedState);
  } catch (error) {
    // Ignore write errors.
    removeItemFromLocalStorage(itemName);
  }
};

export const saveItemToSessionStorage = (itemName, state) => {
  try {
    const storedState = getItemFromSessionStorage(itemName);
    const serializedState = JSON.stringify(
      typeof storedState === "object"
        ? Object.assign(storedState, state)
        : state
    );

    sessionStorage.setItem(itemName, serializedState);
  } catch (error) {
    sessionStorage.removeItem(itemName);
  }
};

export const getItemFromSessionStorage = (
  itemName,
  defaultValue = undefined
) => {
  try {
    const storedState = sessionStorage.getItem(itemName);

    if (storedState === null || storedState === undefined) {
      sessionStorage.removeItem(storedState);
      return defaultValue;
    }

    return JSON.parse(storedState);
  } catch (error) {
    return defaultValue;
  }
};

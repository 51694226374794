import React from "react";
import classNames from "classnames/bind";
import ErrorIcon from "assets/images/error.svg";
import DarkErrorIcon from "assets/images/dark-error.svg";
import { useTranslation } from "react-i18next";
import { isMobile } from "utils/helpers/browser.helpers";

import "./Error.styles.scss";
import { ERROR_CODE_MESSAGE_MAPPINGS } from "utils/constants";

const ErrorComponent = ({
  logo,
  darkMode,
  enterprise,
  errorCode,
  action,
  actionText,
  texts,
}) => {
  const { t } = useTranslation();

  const errorText = () => {
    const errorKey = ERROR_CODE_MESSAGE_MAPPINGS[errorCode];
    if (errorKey && texts?.[errorKey]) return texts[errorKey];
    return t(`errorCode.${errorCode}`);
  };

  const setErrorIcon = () => {
    if (darkMode) return DarkErrorIcon;
    return ErrorIcon;
  };

  return (
    <div
      className={classNames("error-container", {
        dark: darkMode,
        enterprise: enterprise,
      })}
    >
      <div className="app-logo-container">
        <img src={logo} className="logo-img" alt="nope" />
      </div>

      <div className="content-box">
        <img
          className="error-img"
          src={isMobile ? logo : setErrorIcon()}
          alt="nope"
        />

        <p>{errorText()}</p>
        {action && (
          <button
            onClick={() => {
              action();
            }}
          >
            {actionText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;

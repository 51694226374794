import React, { useEffect } from "react";
import classNames from "classnames/bind";
import { isFirefox, isMobile } from "utils/helpers/browser.helpers";
import LightStaticBg from "assets/images/light-static-bg.jpg";
import DarkStaticBg from "assets/images/dark-static-bg.jpg";

import "./Background.styles.scss";

const Background = ({
  children,
  disableScale,
  scaledResolution,
  bgContainerRef,
  darkMode,
  bannerURL,
  enterprise,
  connected,
}) => {
  const backgroundStyle = () => {
    if (connected) {
      return { backgroundImage: "none" };
    }
    const encodedBannerURL = encodeURI(bannerURL);
    if (isFirefox && !bannerURL) {
      return {
        backgroundImage: darkMode
          ? `url(${DarkStaticBg})`
          : `url(${LightStaticBg})`,
      };
    }

    if (enterprise && bannerURL)
      return { backgroundImage: `url("${encodedBannerURL}")` };

    return {};
  };

  return (
    <div
      className={classNames("flex-container", {
        "not-scaled": !scaledResolution && !disableScale,
      })}
    >
      <div
        ref={bgContainerRef}
        className={classNames("bg-container", {
          dark: darkMode,
          "not-scaled": !scaledResolution && !disableScale,
        })}
        style={backgroundStyle()}
      >
        {children}
      </div>
    </div>
  );
};

export default Background;

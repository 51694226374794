import Error from "components/Error/Error.component";
import StreamsLightLogo from "assets/images/streams-light-logo.png";
import React from "react";

const NotFound = () => {
  return (
    <div className="bg-container">
      <Error
        logo={StreamsLightLogo}
        errorCode={404}
        action={() => {
          window.location.replace("https://vagon.io/streams");
        }}
        actionText="Explore Vagon Streams"
      />
    </div>
  );
};

export default NotFound;

import React from "react";
import classNames from "classnames/bind";
import Lock from "assets/images/lock.png";
import { useAPI } from "utils/helpers/api.helpers";
import { API_ROUTES, ERROR_CODES } from "utils/constants";
import { isMobile } from "utils/helpers/browser.helpers";

import "./Password.styles.scss";

const Password = ({
  logo,
  darkMode,
  enterprise,
  streamUID,
  setAuthenticated,
  setErrorCode,
}) => {
  const [password, setPassword] = React.useState("");

  const { api: authenticateUserAPI, isLoading: authenticateUserLoading } =
    useAPI({
      endpoint: API_ROUTES.authenticateUser,
      type: "post",
      onSuccess: (data) => {
        setAuthenticated(true);
      },
      onError: () => {
        setAuthenticated(false);
        setErrorCode(ERROR_CODES.passwordError);
      },
    });

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    authenticateUserAPI({ id: streamUID, password });
  };
  return (
    <div
      className={classNames("email-collect-container", {
        dark: darkMode,
        enterprise: enterprise,
      })}
    >
      <div className="app-logo-container">
        <img src={logo} alt="nope" />
      </div>

      <div className="content-box">
        <img className="error-logo" src={Lock} alt="nope" />

        <form onSubmit={(e) => handleSubmit(e)} className="email-form">
          <label>Stream Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="**********"
          />
          <button disabled={authenticateUserLoading} type="submit">
            Join Stream
          </button>
        </form>
      </div>
    </div>
  );
};

export default Password;
